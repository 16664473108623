import React, { createContext } from 'react'
import { useState } from 'react'

const defaultContext = {
    message: {
        showMessage: true,
        setShowMessage: undefined
    }
}

export const Context = createContext(defaultContext)

export const ContextProvider = ({ children }) => {

    const [showMessage, setShowMessage] = useState(true)

    return (
        <Context.Provider
            value={{message:{ showMessage, setShowMessage }}}
        >
            {children}
        </Context.Provider>
    )
}